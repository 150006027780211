// @flow
import * as React from 'react';
import { RefObject, Suspense, useState } from 'react';
import { AsyncThunk, EntityId } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { ReactComponent as EmptyList } from '@assets/images/empty_list.svg';
import useAppDispatch from '@data/useAppDispatch';
import { TimeLines } from '@data/timeline/timeline.model';
import LoadingPage from '@shared/components/loading-page';
import Rescroller from '../rescroller/Rescroller';

const Post = React.lazy(() => import('../post/Post'));

type TimelineProps = {
  timelineFetcher: AsyncThunk<never, { page: number }, never>;
  selector: (state) => EntityId[];
  pageSelector: (state) => number;
  hasMoreSelector: (state) => boolean;
  handleEditPost?: (id: string) => () => void;
  fetcherProps?: Record<string, any>;
  timeline: TimeLines;
  emptyListMessage?: string;
  className?: string;
  openResharePostDialog?: (id: string) => () => void;
  scroller?: RefObject<HTMLDivElement> | null;
};

const Timeline: React.FC<React.PropsWithChildren<TimelineProps>> = (props) => {
  const {
    selector,
    timelineFetcher,
    children,
    pageSelector,
    hasMoreSelector,
    fetcherProps,
    timeline,
    emptyListMessage,
    className,
    handleEditPost,
    openResharePostDialog,
  } = props;

  const dispatch = useAppDispatch();

  const lastLoadedPage = useSelector(pageSelector);
  const hasMore = useSelector(hasMoreSelector);
  const data: EntityId[] = useSelector(selector);

  const [page, setPage] = useState(0);
  const selfScroller = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    setPage(lastLoadedPage);
  }, [lastLoadedPage]);

  React.useEffect(() => {
    if (page > 0) dispatch(timelineFetcher({ page, ...fetcherProps }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, timelineFetcher]);

  React.useEffect(() => {
    setPage(1);
  }, [timelineFetcher]);

  const fetchNextPage = () => () => {
    setPage(lastLoadedPage + 1);
  };

  const handlePostEdit = React.useMemo(
    () => (id: EntityId) => () => {
      if (handleEditPost) handleEditPost(id as string)();
    },
    [handleEditPost],
  );

  const handleResharePost = React.useMemo(
    () => (id: EntityId) => () => {
      if (openResharePostDialog) openResharePostDialog(id as string)();
    },
    [openResharePostDialog],
  );

  const renderRow = React.useCallback(
    (i: number) => {
      return (
        <Post
          openResharePostDialog={handleResharePost(data[i])}
          postKey={data[i]}
          key={i}
          timeline={timeline}
          onEditPost={handlePostEdit(data[i])}
        />
      );
    },
    [data, timeline, handlePostEdit, handleResharePost],
  );

  if (!hasMore && data.length === 0)
    return (
      <div className='w-full pt-5 pb-5 pl-5 pr-5 flex-col-center'>
        {children}
        <EmptyList />
        {emptyListMessage && (
          <span className='w-full mt-5 text-center text-on-secondary h5'>
            {emptyListMessage}
          </span>
        )}
      </div>
    );

  const { scroller } = props;

  return (
    <div
      className={`flex justify-center w-full h-full ${
        scroller ? '' : 'overflow-y-auto'
      } ${className || ''}`}
      ref={selfScroller}
      id='timeline-scroller'
    >
      <Suspense fallback={<LoadingPage />}>
        <div className='w-full h-full max-w-[540px]'>
          <Rescroller
            className=''
            components={{
              Header: children,
            }}
            renderer={renderRow}
            scroller={scroller || selfScroller}
            loadMore={fetchNextPage()}
            itemsCount={data.length}
            hasMore={hasMore}
          />
        </div>
      </Suspense>
    </div>
  );
};
export default Timeline;
