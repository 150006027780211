import useAppDispatch from '@data/useAppDispatch';
import {
  fetchFriendsBySearch,
  fetchMyCircle,
} from '@data/my-circle/MyCircle.thunk';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { MyCircleSelector } from '@data/my-circle/MyCircle.selector';
import { Friend } from '@data/my-circle/MyCircle.model';

const useFriends = (searchKeywords: string) => {

  const [loadingFriends, setLoadingFriends] = React.useState(false);
  const total = useSelector(MyCircleSelector.selectTotal);
  const totalFriends = useSelector(MyCircleSelector.selectAll);

  const [friends, setFriends] = React.useState<
    {
      name: string;
      avatar: string;
      id: number;
    }[]
  >([]);
  const [loading, setLoading] = React.useState(false);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (total > 0) return;
    setLoadingFriends(true);
    dispatch(fetchMyCircle({ page: 0 })).then(() => setLoadingFriends(false));
  }, [dispatch, total]);

  React.useEffect(() => {
    if (!searchKeywords) {
      setFriends(
        totalFriends.map((user: Friend) => {
            return {
              name: user.info.nickname,
              avatar: user.info.avatar,
              id: user.id,
            };
          },
        ));
      return;
    }
    setLoading(true);

    dispatch(fetchFriendsBySearch({ search: searchKeywords }))
      .then((res) => {
        setFriends(
          res.users.map((user) => {
            return {
              name: user.profile.nick_name,
              avatar: user.profile.img_url,
              id: user.id,
            };
          }),
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dispatch, searchKeywords, totalFriends]);

  return { friends, loading: loading || loadingFriends };
};

export default useFriends;
