// @flow
import * as React from 'react';
import { EntityId } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import moment from 'moment';

import useAppDispatch from '@data/useAppDispatch';
import { fetchMessages, markAsRead } from '@data/chat/Chat.thunk';
import { ChatSelector } from '@data/chat/Chat.selector';
import { Message } from '@data/chat/Chat.model';
import { AppSelector } from '@data/app/app.selector';
import HDivider from '@shared/components/divider/HDivider';
import Rescroller from '@shared/components/rescroller/Rescroller';
import useConversation from '../conversation-list/hooks/useConversation';
import MessageBubble from './components/MessageBubble';
import SendBar from './components/SendBar';
import useSendMessage from './hooks/useSendMessage';
import MessageListHeader from './components/MessageListHeader';
import ReshareBubble
  from '@view/chat/components/messages-list/components/ReshareBubble';

type MessagesListProps = {
  selectedConversation: EntityId | null;
  setSelectedConversation: (id: EntityId | null) => void;
  showMobileChatView?: boolean;
};

const MessagesList: React.FC<MessagesListProps> = ({
                                                     selectedConversation,
                                                     setSelectedConversation,
                                                     showMobileChatView,
                                                   }) => {
  const dispatch = useAppDispatch();
  const [page, setPage] = React.useState(1);
  const messages = useSelector((state: any) =>
    state.chat.messages[selectedConversation || 0]
      ? ChatSelector.messages.selectAll(
        state.chat.messages[selectedConversation || 0],
      )
      : [],
  );
  const [loadedMessages, setLoadedMessages] = React.useState<Message[]>([]);
  const userId = useSelector(AppSelector.selectUserId);
  const scroller = React.useRef<HTMLDivElement>(null);
  const { onSendMessage, pendingMessages } = useSendMessage(
    selectedConversation as number,
  );

  const { user } = useConversation(selectedConversation);

  const getMessages = React.useCallback(() => {
    dispatch(
      fetchMessages({ page, peer: selectedConversation as number }),
    ).then((payload: any) => {
      if (payload && payload.length > 0)
        dispatch(
          markAsRead({
            peer: selectedConversation as number,
            messageId: payload[0].activity.message[0].id,
          }),
        );
    });
  }, [dispatch, page, selectedConversation]);

  React.useEffect(() => {
    if (selectedConversation) {
      setLoadedMessages([]);
      setPage(1);
      getMessages();
    }
  }, [getMessages, selectedConversation]);

  React.useEffect(() => {
    if (selectedConversation && page !== 1) {
      getMessages();
    }
  }, [getMessages, page, selectedConversation]);

  React.useEffect(() => {
    if (messages.length > 0) setLoadedMessages(messages || []);
  }, [messages]);

  const renderRow = (message: Message, renderDate) => {
    return (
      <div key={`m-${message.id}-${message.userId}`}>
        {renderDate && (
          <div
            className={` mt-2 w-full mb-1 caption text-on-secondary flex-col-center`}
            key={message.time}
          >
            <span
              className='pt-1 pb-1 pl-2 pr-2 border rounded-full border-secondary-background'>
              {moment(message.time).format('DD MMMM YYYY')}
            </span>
          </div>
        )}
        {
          message.resharedPost ||
          message.resharedContent

            ?
            <ReshareBubble
              key={message.id}
              message={message.message}
              time={message.time}
              isMine={userId === message.userId}
              isPending={message.pending}
              resharedPost={message.resharedPost}
              resharedContent={message.resharedContent}
            />
            : <MessageBubble
              key={message.id}
              message={message.message}
              time={message.time}
              isMine={userId === message.userId}
              isPending={message.pending} />
        }

      </div>
    );
  };

  const renderMessages = () => {
    let renderDate = false;
    const messages = [...pendingMessages, ...loadedMessages];
    return (
      <div className='flex-1 w-full overflow-auto'>
        <Rescroller
          itemsCount={messages.length}
          renderer={(index: number) => {
            const message = messages[index];
            renderDate =
              index === messages.length - 1 ||
              moment(message.time).format('DD MMMM YYYY') !==
              moment(messages[index + 1].time).format('DD MMMM YYYY');

            return renderRow(message, renderDate);
          }}
          loadMore={() => {
            setPage(page + 1);
          }}
          scroller={scroller}
          hasMore={loadedMessages.length / 50 >= page}
          reverse
        />
      </div>
    );
  };

  return (
    <div className='flex flex-col w-full h-full'>
      <MessageListHeader
        user={user}
        showMobileChatView={showMobileChatView}
        setSelectedConversation={setSelectedConversation}
      />
      <HDivider />
      {renderMessages()}
      {selectedConversation && (
        <div
          className='w-full pt-2 pb-2 pl-5 pr-5 border-t h-[60px] drop-shadow-top'>
          <SendBar placeholder='Type a message' onSend={onSendMessage} />
        </div>
      )}
    </div>
  );
};
export default MessagesList;
