export enum FeedTypes {
  SHARE = 'share',
  // STATUS = 'status',
  MISSION = 'mission',
  QUESTION = 'question',
  HEALTH = 'health_status',

  // MEDICATION_DOC = 'medication_doc',
  // PERSONAL_MEDICATION_DOC = 'personal_medical_doc',
  COMMENT = 'comment',
  POLL = 'poll',
  SOCIAL_EVENT = 'social_event',

  RESHARE = 'reshare',
}

export enum FeedSharingOption {
  PUBLIC = 'public',
  CIRCLE = 'my_network',
  CHANNEL = 'channel',
  PRIVATE = 'private',
  SELF = 'self',
  NETWORK = 'network',
}

export enum FeedPublishStatus {
  SCHEDULED = 'scheduled',
  DRAFT = 'draft',
  FLAGGED = 'flagged',
  PUBLISHED = 'published',
}

export enum ActionsOnFeeds {
  LIKE = 'like',
  BOOKMARK = 'bookmark',
  COMMITMENT = 'commitment',
  DONE = 'done',
  FLAG = 'flag',
  READ = 'read',
}

export enum AttachmentType {
  NONE = 'NONE',
  IMAGE = 'P',
  VIDEO = 'V',
}

export interface Attachment {
  url: string;
  name: string;
  file: File;
  type: AttachmentType;
}

export interface LinkAttachment {
  title: string;
  url: string;
  images: { url: string }[];
  description: string;
}

export enum ActionType {
  DRAFT = 'draft',
  SCHEDULE = 'schedule',
  REVIEW = 'review',
  TIMELINE = 'timeline',
  REPORTEDAE = 'reported_ae',
  AE_REVIEW = 'ae_review',
}

export enum ReviewActionType {
  APPROVE = 'approve',
  REMOVE = 'remove',
}
