import { createAsyncThunk } from '@reduxjs/toolkit';
import createAsyncThunkWithAuth from '@data/createAsyncThunkWithAuth';
import axios from 'axios';
import { ApiEndpoint } from '@utils/types';
import { AnyAction } from 'redux';
import moment from 'moment-timezone';
import {
  FeedPublishStatus,
  FeedSharingOption,
  FeedTypes,
} from '../../models/Feed';
import {
  DraftRequest,
  PollDraftRequest,
  TimeLines,
  TimelineTypes,
} from './timeline.model';
import { getAttachmentRequest } from '../shared/atachment.thunk';
import { FileUtils, ImageUtils, ParamsUtils } from '../../utils';

enum ActionsOnFeeds {
  LIKE = 'like',
  HUG = 'hug',
  WOW = 'wow',
  SAD = 'sad',
  MAD = 'mad',
  BOOKMARK = 'bookmark',
  // COMMITMENT = 'commitment',
  // DONE = 'done',
  FLAG = 'flag',
  // READ = 'read',
}

export const fetchTimeline = createAsyncThunkWithAuth<{ page: number }>(
  'timeline/loadTimeline',
  async (args, urlGenerator) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          urlGenerator(ApiEndpoint.TIMELINE, {
            user: args.userId,
            type: TimelineTypes.AGGREGATED,
            page: args.page,
          }),
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch(reject);
    });
  },
);
export const fetchDrafts = createAsyncThunkWithAuth<{ page: number }>(
  'timeline/loadDrafts',
  async (args, urlGenerator) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          urlGenerator(ApiEndpoint.ADMIN_FEED, {
            user: args.userId,
            type: TimelineTypes.DRAFTS,
            page: args.page,
          }),
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch(reject);
    });
  },
);

export const fetchScheduledPosts = createAsyncThunkWithAuth<{ page: number }>(
  'timeline/loadScheduledPosts',
  async (args, urlGenerator) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          urlGenerator(ApiEndpoint.ADMIN_FEED, {
            user: args.userId,
            type: TimelineTypes.SCHEDULED,
            page: args.page,
          }),
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch(reject);
    });
  },
);
export const fetchBookmarkedPosts = createAsyncThunkWithAuth<{ page: number }>(
  'timeline/loadBookmarkedPosts',
  async (args, urlGenerator) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          urlGenerator(ApiEndpoint.TIMELINE, {
            user: args.userId,
            type: TimelineTypes.BOOKMARKS,
            page: args.page,
          }),
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch(reject);
    });
  },
);
export const fetchUserPosts = createAsyncThunkWithAuth<{
  page: number;
  userId: number;
}>('timeline/loadUserPosts', async (args, urlGenerator) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        urlGenerator(ApiEndpoint.TIMELINE, {
          user: args.userId,
          type: TimelineTypes.USER_POSTS,
          page: args.page,
        }),
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch(reject);
  });
});

export const fetchFlaggedPosts = createAsyncThunkWithAuth<{ page: number }>(
  'timeline/loadFlaggedPosts',
  async (args, urlGenerator) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          urlGenerator(ApiEndpoint.ADMIN_FEED, {
            user: args.userId,
            type: TimelineTypes.FLAGGED,
            page: args.page,
          }),
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch(reject);
    });
  },
);

export const fetchBase64 = createAsyncThunk(
  'timeline/loadBase64',
  async (urls: { url: string; postId: number; propsIndex: number }[]) => {
    return Promise.all(
      urls.map(async (url) => {
        const base64 = await ImageUtils.getBase64FromUrl(url.url);
        return {
          base64,
          postId: url.postId,
          propsIndex: url.propsIndex,
        };
      }),
    );
  },
);

export const fetchAEPosts = createAsyncThunkWithAuth<{ page: number }>(
  'timeline/loadAEPosts',
  async (args, urlGenerator, dispatch) => {
    const responses: any[] = await Promise.all([
      axios.get(
        urlGenerator(ApiEndpoint.FETCH_AE_POSTS, {
          user: args.userId,
          page: args.page,
        }),
      ),
      axios.get(
        urlGenerator(ApiEndpoint.FETCH_AE_COMMENTS, {
          user: args.userId,
          page: args.page,
        }),
      ),
    ]);

    const posts = [...responses[0].data.assets, ...responses[1].data.assets];
    const urls: { url: string; postId: number; propsIndex: number }[] = [];

    for (let i = 0; i < posts.length; i += 1) {
      const props = posts[i]?.props || {};
      const postOrder = props.postOrder || [];
      const files = props.files || [];

      for (let j = 0; j < postOrder.length; j += 1) {
        const postId = postOrder[j];

        if (postId.includes('P')) {
          const url = files[j]?.link;
          // eslint-disable-next-line no-await-in-loop
          if (url) urls.push({ url, postId: posts[i].id, propsIndex: j });
        }
      }
    }

    dispatch(fetchBase64(urls) as unknown as AnyAction);

    return {
      assets: posts,
      page: Math.min(responses[0].data.page, responses[1].data.page),
      total_count:
        responses[0].data.total_count + responses[1].data.total_count,
    };
  },
);

export const fetchReviewedAEPosts = createAsyncThunkWithAuth<{
  month: number;
  year: number;
  page: number;
}>('timeline/loadAECases', async (args, urlGenerator) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        urlGenerator(ApiEndpoint.AE_REVIEWED_FEED, {
          user: args.userId,
          page: args.page,
          month: args.month,
          year: args.year,
        }),
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch(reject);
  });
});
// CURD

export const getFeed = createAsyncThunkWithAuth<{ feedID: number }>(
  'timeline/read',
  async (args, urlGenerator) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          urlGenerator(ApiEndpoint.GET_POST, {
            id: args.feedID,
          }),
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch(reject);
    });
  },
);

export const createFeed = createAsyncThunkWithAuth<
  | (DraftRequest & {
      timeline?: TimeLines;
      isReshared?: boolean;
    })
  | (PollDraftRequest & {
      timeline?: TimeLines;
      isReshared?: boolean;
    })
>('timeline/create', async (args, urlGenerator, dispatch) => {
  const formWithAttachments = (
    await dispatch(
      getAttachmentRequest({
        attachments: (args as DraftRequest).attachments || [],
        link: (args as DraftRequest).attachmentLink,
        key:
          (args as DraftRequest).category !== FeedTypes.POLL ? 'file' : 'img',
      }) as unknown as AnyAction,
    )
  ).payload;

  const form = ParamsUtils.jsonToForm(
    {
      condition: args.community,
      device_id: args.deviceId,
      title: (args as DraftRequest).title,
      relation: args.relation,
      description: (args as DraftRequest).description,
      pub_status: (args as DraftRequest).pubStatus,
      pub_date: (args as DraftRequest).pubDate,
      channel_id: args.channelId,
      user_id: args.userId,
      question: (args as PollDraftRequest).question,
    },
    formWithAttachments,
  );

  for (let i = 0; i < (args as DraftRequest).tags?.length; i += 1) {
    form.append('tag_ids[]', (args as DraftRequest).tags[i]);
  }

  if (
    args.relation === FeedSharingOption.PRIVATE &&
    (args as DraftRequest).recipientIds !== undefined
  ) {
    const recipientIds = (args as DraftRequest).recipientIds || [];

    for (let i = 0; i < recipientIds.length; i += 1) {
      form.append('recipient_id[]', recipientIds[i]);
    }
  }

  if (args.isReshared) {
    if (args.id) form.append('reshared_asset_id', args.id);
    else if (args.contentId) {
      form.append('reshared_asset_id', args.contentId);
      form.append('type', 'discover');
    }
  }

  return new Promise((resolve, reject) => {
    if ((args as DraftRequest).category !== FeedTypes.POLL) {
      if (args.isReshared) {
        axios
          .post(
            args.contentId
              ? urlGenerator(ApiEndpoint.CONTENT_RESHARE, {
                  user: args.userId,
                  id: args.contentId,
                })
              : urlGenerator(ApiEndpoint.ASSETS, {
                  user: args.userId,
                  category: FeedTypes.RESHARE,
                }),
            form,
          )
          .then((res) => {
            resolve(res.data);
          })
          .catch(reject);
      } else
        axios
          .post(
            urlGenerator(ApiEndpoint.ASSETS, {
              user: args.userId,
              category: (args as DraftRequest).category,
            }),
            form,
          )
          .then((res) => {
            resolve(res.data);
          })
          .catch(reject);
    } else {
      const pollEndTime = (
        (args as DraftRequest).pubStatus === FeedPublishStatus.PUBLISHED
          ? moment()
          : moment((args as DraftRequest).pubDate)
      )
        .add((args as DraftRequest).endTime?.day, 'days')
        .add((args as DraftRequest).endTime?.hour, 'hours')
        .add((args as DraftRequest).endTime?.minute, 'minutes');

      if (args.options === undefined) return;
      for (let i = 0; i < args.options.length || 0; i += 1) {
        form.append(`options[]`, `${args.options[i]}`);
      }

      form.append('end_time', pollEndTime.toISOString());
      form.append('question', (args as DraftRequest).title);
      axios
        .post(
          urlGenerator(ApiEndpoint.CREATE_POLL, {
            user: args.userId,
          }),
          form,
        )
        .then(async (res) => {
          dispatch(
            getFeed({
              feedID: res.data.activity_object_id,
            }) as unknown as AnyAction,
          ).then((res2) => {
            resolve(res2.payload);
          });
        })
        .catch(reject);
    }
  });
});

export const updateFeed = createAsyncThunkWithAuth<
  DraftRequest & { id: string; timeline?: TimeLines }
>('timeline/update', async (args, urlGenerator, dispatch) => {
  const formWithAttachments = (
    await dispatch(
      getAttachmentRequest({
        attachments: (args as DraftRequest).attachments || [],
        link: (args as DraftRequest).attachmentLink,
        key:
          (args as DraftRequest).category !== FeedTypes.POLL ? 'file' : 'img',
      }) as unknown as AnyAction,
    )
  ).payload;

  for (let i = 0; i < (args as DraftRequest).tags?.length; i += 1) {
    formWithAttachments.append('tag_ids[]', (args as DraftRequest).tags[i]);
  }

  if (args.category === FeedTypes.POLL) {
    return new Promise((resolve, reject) => {
      const pollEndTime = (
        (args as DraftRequest).pubStatus === FeedPublishStatus.PUBLISHED
          ? moment()
          : moment((args as DraftRequest).pubDate)
      )
        .add((args as DraftRequest).endTime?.day, 'days')
        .add((args as DraftRequest).endTime?.hour, 'hours')
        .add((args as DraftRequest).endTime?.minute, 'minutes');

      // if (args.options === undefined) return;
      // for (let i = 0; i < args.options.length || 0; i += 1) {
      //   formWithAttachments.append(`options[]`, `${args.options[i]}`);
      // }

      for (let i = 0; i < (args as DraftRequest).tags?.length; i += 1) {
        formWithAttachments.append('tag_ids[]', (args as DraftRequest).tags[i]);
      }

      axios
        .put(
          urlGenerator(ApiEndpoint.EDIT_POLL, {
            user: args.userId,
            pollId: (args as DraftRequest).pollId,
          }),
          ParamsUtils.jsonToForm(
            {
              // question: args.title,
              // end_time: pollEndTime.toISOString(),
              // pub_status: args.pubStatus,
              // pub_date: args.pubDate,
            },
            formWithAttachments,
          ),
        )
        .then((res) => {
          dispatch(
            getFeed({
              feedID: res.data.activity_object_id,
            }) as unknown as AnyAction,
          ).then((res2) => {
            resolve(res2.payload);
          });
        })
        .catch(reject);
    });
  } else {
    return new Promise((resolve, reject) => {
      axios
        .put(
          urlGenerator(ApiEndpoint.EDIT_ASSET, {
            user: args.userId,
            category: args.category,
            id: args.id || 0,
          }),
          ParamsUtils.jsonToForm(
            {
              title: args.title,
              relation: args.relation,
              description: args.description,
              pub_status: args.pubStatus,
              pub_date: args.pubDate,
            },
            formWithAttachments,
          ),
        )
        .then((res) => {
          resolve(res.data);
        })
        .catch(reject);
    });
  }
});

export const deleteFeed = createAsyncThunkWithAuth<{
  feedID: number;
  category: FeedTypes;
}>('timeline/delete', async (args, urlGenerator) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(
        urlGenerator(ApiEndpoint.DELETE_ASSET, {
          user: args.userId,
          id: args.feedID,
          category: args.category,
        }),
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch(reject);
  });
});

export const deleteComment = createAsyncThunkWithAuth<{
  feedID: number;
  category: FeedTypes;
  parentID: number;
  grandParentID?: number;
}>('timeline/comment/delete', async (args, urlGenerator) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(
        urlGenerator(ApiEndpoint.DELETE_ASSET, {
          user: args.userId,
          id: args.feedID,
          category: args.category,
        }),
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch(reject);
  });
});

export const getFeedWithoutSaving = createAsyncThunkWithAuth<{
  feedID: number;
}>('timeline/readNoSaving', async (args, urlGenerator) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        urlGenerator(ApiEndpoint.GET_POST, {
          id: args.feedID,
        }),
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch(reject);
  });
});

// Actions on feeds

export const ReactOnFeed = createAsyncThunkWithAuth<{
  feedID: number;
  timeline: TimeLines;
  channelId;
  reaction:
    | ActionsOnFeeds.LIKE
    | ActionsOnFeeds.HUG
    | ActionsOnFeeds.SAD
    | ActionsOnFeeds.MAD
    | ActionsOnFeeds.WOW;
}>('timeline/action/react', async (args, urlGenerator) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        urlGenerator(ApiEndpoint.ACTION_ON_ASSETS, {
          user: args.userId,
          feedID: JSON.stringify(args.feedID),
          action: args.reaction,
        }),
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch(reject);
  });
});

export const bookmarkFeed = createAsyncThunkWithAuth<{
  feedID: number;
  timeline: TimeLines;
  channelId;
}>('timeline/action/bookmark', async (args, urlGenerator) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        urlGenerator(ApiEndpoint.ACTION_ON_ASSETS, {
          user: args.userId,
          feedID: JSON.stringify(args.feedID),
          action: ActionsOnFeeds.BOOKMARK,
        }),
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch(reject);
  });
});

export const flagFeed = createAsyncThunkWithAuth<{
  feedID: number;
  timeline: TimeLines;
  channelId;
}>('timeline/action/flag', async (args, urlGenerator) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        urlGenerator(ApiEndpoint.ACTION_ON_ASSETS, {
          user: args.userId,
          feedID: JSON.stringify(args.feedID),
          action: ActionsOnFeeds.FLAG,
        }),
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch(reject);
  });
});

export const reactOnComment = createAsyncThunkWithAuth<{
  feedID: number;
  parent: number;
  grandParent?: number;
  reaction;
}>('timeline/comment/action/react', async (args, urlGenerator) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        urlGenerator(ApiEndpoint.ACTION_ON_ASSETS, {
          user: args.userId,
          feedID: JSON.stringify(args.feedID),
          action: args.reaction,
        }),
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch(reject);
  });
});

export const bookmarkComment = createAsyncThunkWithAuth<{
  feedID: number;
  parent: number;
  grandParent?: number;
}>('timeline/comment/action/bookmark', async (args, urlGenerator) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        urlGenerator(ApiEndpoint.ACTION_ON_ASSETS, {
          user: args.userId,
          feedID: JSON.stringify(args.feedID),
          action: ActionsOnFeeds.BOOKMARK,
        }),
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch(reject);
  });
});
export const flagComment = createAsyncThunkWithAuth<{
  feedID: number;
  parent: number;
  grandParent?: number;
}>('timeline/comment/action/flag', async (args, urlGenerator) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        urlGenerator(ApiEndpoint.ACTION_ON_ASSETS, {
          user: args.userId,
          feedID: JSON.stringify(args.feedID),
          action: ActionsOnFeeds.FLAG,
        }),
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch(reject);
  });
});

// Undo Actions on feeds

export const removeReactionOnFeed = createAsyncThunkWithAuth<{
  feedID: number;
  timeline: TimeLines;
  channelId;
  reaction;
}>('timeline/action/reaction', async (args, urlGenerator) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(
        urlGenerator(ApiEndpoint.ACTION_ON_ASSETS, {
          user: args.userId,
          feedID: JSON.stringify(args.feedID),
          action: args.reaction,
        }),
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch(reject);
  });
});

export const unbookmarkFeed = createAsyncThunkWithAuth<{
  feedID: number;
  timeline: TimeLines;
  channelId;
}>('timeline/action/unbookmark', async (args, urlGenerator) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(
        urlGenerator(ApiEndpoint.ACTION_ON_ASSETS, {
          user: args.userId,
          feedID: JSON.stringify(args.feedID),
          action: ActionsOnFeeds.BOOKMARK,
        }),
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch(reject);
  });
});

export const unflagFeed = createAsyncThunkWithAuth<{
  feedID: number;
  timeline: TimeLines;
  channelId;
}>('timeline/action/unflag', async (args, urlGenerator) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(
        urlGenerator(ApiEndpoint.ACTION_ON_ASSETS, {
          user: args.userId,
          feedID: JSON.stringify(args.feedID),
          action: ActionsOnFeeds.FLAG,
        }),
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch(reject);
  });
});

export const removeReactionOnComment = createAsyncThunkWithAuth<{
  feedID: number;
  parent: number;
  grandParent?: number;
  reaction;
}>('timeline/comment/action/react', async (args, urlGenerator) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(
        urlGenerator(ApiEndpoint.ACTION_ON_ASSETS, {
          user: args.userId,
          feedID: JSON.stringify(args.feedID),
          action: args.reaction,
        }),
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch(reject);
  });
});

export const unflagComment = createAsyncThunkWithAuth<{
  feedID: number;
  parent: number;
  grandParent?: number;
}>('timeline/comment/action/unflag', async (args, urlGenerator) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(
        urlGenerator(ApiEndpoint.ACTION_ON_ASSETS, {
          user: args.userId,
          feedID: JSON.stringify(args.feedID),
          action: ActionsOnFeeds.FLAG,
        }),
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch(reject);
  });
});

export const unbookmarkComment = createAsyncThunkWithAuth<{
  feedID: number;
  parent: number;
  grandParent?: number;
}>('timeline/comment/action/unbookmark', async (args, urlGenerator) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(
        urlGenerator(ApiEndpoint.ACTION_ON_ASSETS, {
          user: args.userId,
          feedID: JSON.stringify(args.feedID),
          action: ActionsOnFeeds.BOOKMARK,
        }),
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch(reject);
  });
});

export const publishComment = createAsyncThunkWithAuth<{
  parentID: number;
  description: string;
  grandParentID?: number;
}>('timeline/comment/publish', async (args, urlGenerator) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        urlGenerator(ApiEndpoint.POST_COMMENT, {
          user: args.userId,
        }),
        ParamsUtils.jsonToForm({
          parent_id: String(args.parentID),
          description: args.description,
        }),
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch(reject);
  });
});

export const updateComment = createAsyncThunkWithAuth<{
  commentID: number;
  parentID: number;
  description: string;
  grandParentID?: number;
}>('timeline/comment/update', async (args, urlGenerator) => {
  return new Promise((resolve, reject) => {
    axios
      .put(
        urlGenerator(ApiEndpoint.EDIT_ASSET, {
          user: args.userId,
          category: FeedTypes.COMMENT,
          id: args.commentID,
        }),
        ParamsUtils.jsonToForm({
          description: args.description,
        }),
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch(reject);
  });
});

export const submitReview = createAsyncThunk(
  'timeline/review/submit',
  async ({
    review,
    url,
  }: {
    review: { id: number; status: 'accepted' | 'rejected' };
    url: string;
  }) => {
    return new Promise((resolve, reject) => {
      axios
        .post(url)
        .then((res) => {
          resolve(res.data);
        })
        .catch(reject);
    });
  },
);

export const submitReviews = createAsyncThunkWithAuth<{
  reviews: { id: number; status: 'accepted' | 'rejected' }[];
}>('timeline/reviews/submit', async (args, urlGenerator, dispatch) => {
  return Promise.all(
    args.reviews.map(async ({ id, status }) => {
      return dispatch(
        submitReview({
          review: { id, status },
          url: urlGenerator(ApiEndpoint.REVIEW_ASSET, {
            user: args.userId,
            asset_id: id,
            review_action: status === 'accepted' ? 'approve' : 'remove',
          }),
        }),
      );
    }),
  );
});

// export const rejectAE = createAsyncThunkWithAuth(
//   'timeline/adverse-event/reject',
//   async ({ id, url }: { id; url: string }) => {
//     return new Promise((resolve, reject) => {
//       return axios.put(
//         URLUtils.generateApiRoute(ApiEndpoint.REJECT_AE, {
//           user: apiData.userId,
//           assetId: id,
//         }),
//         ParamsUtils.jsonToForm(body),
//       );
//     });
//   },
// );
//
// export const confirmAE = createAsyncThunk(
//   'timeline/adverse-event/confirm',
//   async ({
//     review,
//     authData,
//   }: {
//     review: { id: number; comment?: string; screenshot?: string };
//     authData: { userId; deviceId; community };
//   }) => {
//     const response = await timelineApi.confirmAE(review, authData);
//     return response.data;
//   },
// );

export const reviewAE = createAsyncThunkWithAuth<{
  reviews: {
    id: number;
    status: 'AE' | 'NotAE';
    comment?: string;
    screenshot?: string;
  }[];
}>('timeline/adverse-event/review', async (args, urlGenerator, dispatch) => {
  return Promise.all(
    args.reviews.map(async ({ id, status, comment, screenshot }) => {
      // const review = { id, status, comment, screenshot };
      // const authData = {
      //   userId: args.userId,
      //   deviceId: args.deviceId,
      //   community: args.community,
      // };
      if (status === 'NotAE') {
        return axios.put(
          urlGenerator(ApiEndpoint.REJECT_AE, {
            user: args.userId,
            assetId: id,
          }),
        );
      }
      if (status === 'AE') {
        const body = ParamsUtils.jsonToForm({
          justification: comment,
        });

        if (screenshot) {
          body.append(
            'img',
            FileUtils.dataURLtoFile(screenshot, `screenshot-${id}.png`),
          );
        }

        return axios.post(
          urlGenerator(ApiEndpoint.ACCEPT_AE, {
            user: args.userId,
            assetId: id,
          }),
          body,
        );
      }
      return null;
    }),
  );
});

export const addExternalCaseID = createAsyncThunkWithAuth<{
  caseID: string;
  externalID: string;
}>('timeline/adverse-event/external-case-id', async (args, urlGenerator) => {
  return new Promise((resolve, reject) => {
    axios
      .put(
        urlGenerator(ApiEndpoint.UPDATE_PARTNER_CASE_ID, {
          user: args.userId,
          case_id: args.caseID,
        }),
        ParamsUtils.jsonToForm({
          external_id: args.externalID,
        }),
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch(reject);
  });
});

export const fetchGroupTimeline = createAsyncThunkWithAuth<{
  channelId: number;
  page: number;
}>('timeline/group/fetch', async (args, urlGenerator) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        urlGenerator(ApiEndpoint.GET_USER_ACTIVITIES, {
          user: args.userId,
          page: args.page,
          groupId: args.channelId,
        }),
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch(reject);
  });
});

export const votePoll = createAsyncThunkWithAuth<{
  pollID: number;
  optionID: number;
}>('timeline/poll/vote', async (args, urlGenerator, dispatch) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        urlGenerator(ApiEndpoint.VOTE_POLL, {
          user: args.userId,
          pollId: args.pollID,
        }),
        ParamsUtils.jsonToForm({
          poll_option_id: args.optionID,
        }),
      )
      .then((res) => {
        if (res.data.activity_object_id)
          dispatch(
            getFeed({
              feedID: res.data.activity_object_id,
            }),
          );
        else reject();
      })
      .catch(reject);
  });
});

export const fetchUsersReaction = createAsyncThunkWithAuth<{
  feedID: number;
  page?: number;
}>('timeline/reactions/fetch', async (args, urlGenerator) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        urlGenerator(ApiEndpoint.GET_REACTIONS, {
          user: args.userId,
          feedId: args.feedID,
          page: args.page || 1,
        }),
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch(reject);
  });
});

const TimelineThunk = { fetchTimeline };
export default TimelineThunk;
