import React from 'react';
import { DefaultValueContext } from '@shared/components/make-post/hooks/useDefaultValue';
import { Draft } from '@shared/components/make-post/hooks/useDraftActions';
import { useSelector } from 'react-redux';
import TimelineSelectors from '@data/timeline/timeline.selector';
import { SharedPostDetails } from '@shared/components/shared-post/SharedPostDetails';
import { ContentsSelectors } from '@data/contents/Contents.selectors';
import { EntityId } from '@reduxjs/toolkit';
import { SharedContentDetails } from '@shared/components/shared-post/SharedContentDetails';

export const MakePostReshareDetails: React.FC<{
  isReshareMode: boolean;
  contentId?: string;
}> = ({ isReshareMode, contentId }) => {
  const defaultValue = React.useContext(DefaultValueContext) as Draft;

  const resharedPost = useSelector((state: any) => {
    if (contentId) return null;
    return TimelineSelectors.selectPost(state, defaultValue.id);
  });

  const content = useSelector((state: any) => {
    return ContentsSelectors.contents.selectById(
      state.contents,
      contentId as EntityId,
    );
  });

  if (!isReshareMode || (!resharedPost && !content)) return null;

  return (
    <div className="pl-3 pr-3">
      {resharedPost && <SharedPostDetails post={resharedPost} />}
      {content && <SharedContentDetails content={content} />}
    </div>
  );
};
