import React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import SendIcon from '@mui/icons-material/Send';
import ShareIcon from '@mui/icons-material/Share';

type PostReactionsMenuProps = {
  openResharePostDialog?: () => void;
  handleShareOnChat?: () => void;
  handleClose: () => void;
  anchorEl: HTMLElement | null;
};

const PostShareMenu: React.FC<PostReactionsMenuProps> = ({
                                                           handleShareOnChat,
                                                           anchorEl,
                                                           handleClose,
                                                           openResharePostDialog,
                                                         }) => {
  const open = Boolean(anchorEl as HTMLElement);


  return (
    <Menu
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      id='demo-customized-menu'
      MenuListProps={{
        'aria-labelledby': 'demo-customized-button',
      }}
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
    >
      <MenuItem
        onClick={handleShareOnChat}
        disableRipple
        className='h-12 p-2 uppercase w-[200px] text-on-secondary'
      >
        <SendIcon className='mr-2 text-brand-primary' />
        SEND A CHAT
      </MenuItem>
      {openResharePostDialog && (
        <MenuItem
          onClick={() => {
            if (openResharePostDialog) {
              openResharePostDialog();
            }
            handleClose();
          }}
          disableRipple
          className='h-12 p-2 w-[200px] text-on-secondary'
        >
          <ShareIcon className='mr-2 uppercase text-brand-primary' />
          Share in the feed
        </MenuItem>
      )}
    </Menu>
  );
};
export default PostShareMenu;
