import * as React from 'react';
import { Card } from '@mui/material';
import Header from '@assets/images/survey/Header.png';
import Footer from '@assets/images/survey/Footer.png';
import LockIcon from '@mui/icons-material/Lock';

const SurveyView: React.FC<{
  cover?: string;
  title?: string;
  contentType?: {
    icon: () => React.ReactNode;
    value: string;
  };
  surveyLink?: string;
  summary?: string;
}> = ({ cover, title, contentType, summary, surveyLink }) => {
  // const navigate = useNavigate();
  const [showSurvey, setShowSurvey] = React.useState(false);

  if (showSurvey) {
    return (
      <iframe
        src={surveyLink}
        width="100%"
        height="100%"
        frameBorder="0"
        marginHeight={0}
        marginWidth={0}
      >
        Loading…
      </iframe>
    );
  }

  return (
    <Card
      className={`h-full overflow-auto    max-w-[760px] bg-lighter-background `}
    >
      <div className="relative flex flex-col h-full">
        <img
          src={Header}
          className="absolute top-0 object-contain h-fit"
          alt=""
        />
        <p className="absolute w-full h5 text-on-primary top-8 left-4">
          Stronger Together
        </p>
        <div className="flex flex-col pb-3 bg-white h-fit">
          <img
            src={cover}
            alt={title}
            className="object-cover w-full h-[25%] max-h-[200px]"
          />
          <div className="flex items-center justify-start w-full pl-3 mt-4 caption text-[#4D565F]">
            <div className="w-4 h-4 mr-2">{contentType?.icon()}</div>
            {contentType?.value}
          </div>
          <p className="w-full pl-3 mt-2 mb-2 h5 text-on-primary text-start">
            {title}
          </p>
          <p className="w-full pl-3 pr-3 mb-3 body1 text-on-primary text-start">
            {summary}
          </p>
          <p className="flex flex-row justify-start w-full pl-3 pr-3 mb-3 overline text-on-primary text-start text-secondary">
            <LockIcon className="w-4 h-4 mr-1" /> All your answers are private.
          </p>
          <div className="flex justify-end w-full pr-3">
            <button
              className="h-10 text-white button bg-brand-primary rounded-[100px] w-[128px]"
              onClick={() => setShowSurvey(true)}
            >
              take the survey
            </button>
          </div>
        </div>
        <img
          src={Footer}
          className="absolute bottom-0 object-contain h-fit"
          alt=""
        />
      </div>
    </Card>
  );
};

export default SurveyView;

//
// <div className="relative pl-6 pr-6">

// </div>
