// @flow
import * as React from 'react';
import moment from 'moment';
import LoadingSpinner
  from '@hooks/use-loading/useLoading/components/LoadingSpinner';
import { Feed } from '@data/timeline/timeline.model';
import {
  SharedPostDetails,
} from '@shared/components/shared-post/SharedPostDetails';
import { ContentTypes } from '@data/contents/Contents.model';
import {
  SharedContentDetails,
} from '@shared/components/shared-post/SharedContentDetails';

type MessageBubbleProps = {
  message: string;
  time: string;
  isMine: boolean;
  isPending?: boolean;
  resharedPost?: Feed
  resharedContent?: {
    id: number;
    title: string;
    cover: string;
    type: ContentTypes;
  }
};

const ReshareBubble: React.FC<MessageBubbleProps> = ({
                                                       message,
                                                       isMine,
                                                       time,
                                                       isPending = false,
                                                       resharedPost,
                                                       resharedContent,
                                                     }) => {
  const containerClass = isMine
    ? ' text-on-primary rounded-bl-[10px]'
    : ' text-white rounded-br-[10px]';

  return (
    <div
      className={`w-full pt-2 pb-1 pr-5 pl-5  flex ${
        isMine ? 'justify-end' : 'justify-start'
      }`}
    >
      <div
        className={`${containerClass} body2 relative w-full max-w-[400px] rounded-t-[10px] flex flex-col`}
      >
        {
          resharedPost && (
            <SharedPostDetails post={resharedPost}
                               clickable
                               time={moment(time).format('HH:mm A')}
            />
          )
        }
        {
          resharedContent && (
            <SharedContentDetails content={resharedContent}
                                  clickable
                                  time={moment(time).format('HH:mm A')}
            />)
        }
        {isPending && (
          <div className='absolute w-5 h-5 bottom-3 left-4'>
            <LoadingSpinner />
          </div>
        )}
      </div>
    </div>
  );
};

export default ReshareBubble;
