import { getContentType } from '@data/contents/Contents.factory';
import { Feed } from './timeline.model';
import { FeedTypes } from '../../models/Feed';

export const convertPollResponse = (poll: Record<string, any>) => {
  return {
    id: poll.id,
    active: poll.active,
    options:
      poll.options
        ?.map((option: Record<string, any>, index: number) => ({
          id: option.id,
          text: option.text,
          votes: poll.results[`option_${index + 1}`] || 0,
          voted: poll.votes[index],
        }))
        .sort((a, b) => a.id - b.id) || [],
    voted: poll.user_answered_poll,
    votedCount: poll.voted_count,
    endTime: poll.end_time,
  };
};
export const convertTimelineResponse = (
  feed: Record<string, any>,
  channelId?: number,
): Feed => {
  const comments: Record<string, Feed> = {};
  const ids: number[] = [];
  if (feed.children) {
    for (let i = 0; i < feed.children?.length; i += 1) {
      comments[feed.children[i].id] = convertTimelineResponse(feed.children[i]);
      ids.push(feed.children[i].id);
    }
  }
  return {
    // adverseEvent: {},
    attachments: feed.props,
    // comment: {comments: undefined, replyCount: 0},
    comments: feed.children
      ? {
        comments,
        ids,
      }
      : undefined,
    // event: {eventDate: ""},
    id: feed.id,
    info: {
      category:
        feed.category === 'reshared_item' ? FeedTypes.SHARE : feed.category,
      description: feed.description,
      done: feed.done,
      pubDate: feed.pub_date,
      createdAt: feed.created_at,
      pubStatus: feed.pub_status,
      title: feed.title,
      visibility: feed.visibility,
      parentId: feed.parent_id,
      channelId,
    },
    status: {
      bookmarked: feed.bookmarked,
      commentsCount: feed.comment_count,
      commitmentsCount: feed.commitment_count,
      committed: feed.committed,
      doneCount: feed.done_count,
      enableComments: feed.enable_comments,
      flagged: feed.flagged,
      flagReviewedAt: feed.flag_reviewed_at,
      reactions: {
        like: feed.reactions?.like,
        likeCount: feed.like_count,
        hug: feed.reactions?.hug,
        hugCount: feed.hug_count,
        mad: feed.reactions?.mad,
        madCount: feed.mad_count,
        sad: feed.reactions?.sad,
        sadCount: feed.sad_count,
        wow: feed.reactions?.wow,
        wowCount: feed.wow_count,
      },
    },
    adverseEvent: {
      isAPotentialAE: Boolean(feed.isAPotentialAE),
      pvAutoDetected: feed.pv_auto_detected,
      pvManualReviewDone: feed.pv_manual_review_done,
      pvReported: feed.pv_reported,
      curatioCaseId: feed.curatio_case_id,
      externalId: feed.external_id,
      identifiedBy: feed.identified_by,
      reportedBy: feed.reported_by,
      reportedAt: feed.reported_at,
      reportedDate: feed.reportedDate,
      reviewerComment: feed.reviewer_comment,
    },
    event: {
      eventId: feed.props.id,
      eventDate: feed.event_date,
      joinedEvent: feed.joined_event,
      joinedEventCount: feed.joined_event_count,
      startTime: feed.props.start_time,
      endTime: feed.props.end_time,
      cover: feed.props.img_url,
      isActive: feed.props.is_active,
      location: feed.props.location_url,
      isLocationExternal: feed.props.is_location_external,
    },
    reshared: feed.props.asset
      ? convertTimelineResponse(feed.props.asset)
      : undefined,
    publisher: {
      name: feed.user.nick_name,
      avatar: feed.user.img_url,
      id: feed.user.id,
    },
    resharedContent: feed.props.reshared_item
      ? {
        id: feed.props.reshared_item.id,
        title: feed.props.reshared_item.title,
        cover: feed.props.reshared_item.cover_url,
        type: getContentType(feed.props.reshared_item.type),
      }
      : undefined,
    tags: (feed.tags || []).map((tag: Record<string, any>) => tag.name),
    poll:
      feed.category === FeedTypes.POLL
        ? convertPollResponse({
          ...feed.props,
          votes: [feed.voted1, feed.voted2, feed.voted3, feed.voted4],
          user_answered_poll: [1, 2, 3, 4, 5].some(
            (index) => feed[`voted${index}`],
          ),
          voted_count: feed.voted_count,
        })
        : undefined,
  };
};

export const updateFeedStatus = (state, change) => {
  const status = { ...state.status };

  const reactions = { ...status.reactions };

  if (
    change.like !== undefined ||
    change.hug !== undefined ||
    change.mad !== undefined ||
    change.sad !== undefined ||
    change.wow !== undefined
  ) {
    ['like', 'hug', 'mad', 'sad', 'wow'].forEach((reaction) => {
      if (change[reaction] !== undefined) {
        reactions[`${reaction}Count`] += change[reaction] ? 1 : -1;
      } else {
        reactions[`${reaction}Count`] += reactions[reaction] ? -1 : 0;
      }
    });

    reactions.like = Boolean(change.like);
    reactions.hug = Boolean(change.hug);
    reactions.mad = Boolean(change.mad);
    reactions.sad = Boolean(change.sad);
    reactions.wow = Boolean(change.wow);
  }

  if (change.bookmark !== undefined) status.bookmarked = change.bookmark;

  if (change.flag !== undefined) status.flagged = change.flag;

  return {
    ...status,
    reactions,
  };
};

export const updateCommentStatus = (state, arg, change) => {
  const isReply = Boolean(arg.grandParent);

  const comments = { ...state.comments };

  let status = {};
  if (isReply)
    status = comments.comments[arg.parent].comments.comments[arg.feedID].status;
  else status = comments.comments[arg.feedID].status;

  const reactions = { ...(status as FeedStatus).reactions };

  if (
    change.like !== undefined ||
    change.hug !== undefined ||
    change.mad !== undefined ||
    change.sad !== undefined ||
    change.wow !== undefined
  ) {
    ['like', 'hug', 'mad', 'sad', 'wow'].forEach((reaction) => {
      if (change[reaction] !== undefined) {
        reactions[`${reaction}Count`] += change[reaction] ? 1 : -1;
      } else {
        reactions[`${reaction}Count`] += reactions[reaction] ? -1 : 0;
      }
    });

    reactions.like = Boolean(change.like);
    reactions.hug = Boolean(change.hug);
    reactions.mad = Boolean(change.mad);
    reactions.sad = Boolean(change.sad);
    reactions.wow = Boolean(change.wow);
  }

  if (change.bookmark !== undefined)
    status = {
      ...status,
      bookmarked: change.bookmark,
    };

  if (change.flag !== undefined)
    status = {
      ...status,
      flagged: change.flag,
    };

  if (!isReply) {
    comments.comments = {
      ...comments.comments,
      [arg.feedID]: {
        ...comments.comments[arg.feedID],
        status: {
          ...status,
          reactions,
        },
      },
    };
  } else {
    comments.comments = {
      ...comments.comments,
      [arg.parent]: {
        ...comments.comments[arg.parent],
        comments: {
          ...comments.comments[arg.parent].comments,
          comments: {
            ...comments.comments[arg.parent].comments.comments,
            [arg.feedID]: {
              ...comments.comments[arg.parent].comments.comments[arg.feedID],
              status: {
                ...status,
                reactions,
              },
            },
          },
        },
      },
    };
  }

  return comments;
};

export const upsertComment = (state, arg, comment) => {
  const isReply = Boolean(arg.grandParentID);

  const comments = { ...state.comments };

  if (comment) {
    if (!isReply) {
      comments.comments = {
        ...comments.comments,
        [comment.id]: comment,
      };
      comments.ids = [comment.id, ...comments.ids];
    } else {
      comments.comments = {
        ...comments.comments,
        [arg.parentID]: {
          ...comments.comments[arg.parentID],
          comments: {
            comments: {
              ...comments.comments[arg.parentID].comments.comments,
              [comment.id]: comment,
            },
            ids: [comment.id, ...comments.comments[arg.parentID].comments.ids],
          },
        },
      };
    }
  }

  return comments;
};

export const removeComment = (state, arg, id) => {
  const isReply = Boolean(arg.grandParentID);

  const comments = { ...state.comments };

  if (id) {
    if (!isReply) {
      comments.comments = Object.fromEntries(
        Object.entries(comments.comments).filter(
          ([key]) => String(key) !== String(id),
        ),
      );
      comments.ids = comments.ids.filter((i) => String(i) !== String(id));
    } else {
      comments.comments = {
        ...comments.comments,
        [arg.parentID]: {
          ...comments.comments[arg.parentID],
          comments: {
            comments: Object.fromEntries(
              Object.entries(
                comments.comments[arg.parentID].comments.comments,
              ).filter(([key]) => key !== id),
            ),
            ids: comments.comments[arg.parentID].comments.ids.filter(
              (i) => i !== id,
            ),
          },
        },
      };
    }
  }

  return comments;
};

const ALLOWED_FEED_TYPES = [
  FeedTypes.SHARE,
  FeedTypes.QUESTION,
  FeedTypes.POLL,
  FeedTypes.SOCIAL_EVENT,
  FeedTypes.MISSION,
];

export const filterTimeline = (posts: Feed[]): Feed[] =>
  posts.filter((post) => ALLOWED_FEED_TYPES.includes(post.info.category));

export const replaceImageURLWithBase64 = (
  props: any,
  changes: { base64: string; propsIndex: number }[],
) => {
  const changeIndexes = changes.map((change) => change.propsIndex);

  return {
    ...props,
    files: props.files.map((file: any, index: number) => {
      if (changeIndexes.includes(index)) {
        return {
          ...file,
          link: changes.find((change) => change.propsIndex === index)?.base64,
        };
      }
      return file;
    }),
  };
};
