import React from 'react';
import { Card, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
// import { useTranslation } from 'react-i18next';
import { MakePostDialogOpeningMode } from '@shared/components/make-post/models/MakePostAction';
import MakePostHeader from '@shared/components/make-post/components/make-post-header/MakePostHeader';
import MakePostEditor from '@shared/components/make-post/components/make-post-editor/MakePostEditor';
import MakePostFooter from '@shared/components/make-post/components/make-post-footer/MakePostFooter';
import { DefaultValueContext } from '@shared/components/make-post/hooks/useDefaultValue';
import {
  DraftActions,
  DraftContext,
  PollDraft,
} from '@shared/components/make-post/hooks/useDraftActions';

type MakePostDialogProps = {
  open?: MakePostDialogOpeningMode;
  closeDialog?: () => void;
  contentId?: string;
};

const MakePostDialog: React.FC<MakePostDialogProps> = ({
  open,
  closeDialog,
  contentId,
}) => {
  // const { t } = useTranslation();
  // // const [body, setBody] = React.useState<string>('');
  // // const { renderLink, hasLink } = useLinkPreview(body, true); TODO: fix this
  // const [attachments, setAttachments] = React.useState<
  //   { file: File; content: string }[]
  // >([]);
  // const [attachmentSelectionType, setAttachmentSelectionType] =
  //   React.useState<string>('');
  //
  const [showEmojiMenu, setShowEmojiMenu] = React.useState<boolean>(false);
  //

  //
  // const addNewAttachment = (f: { file: File; content: string }) => {
  //   setAttachments([...attachments, f]);
  // };
  //
  // const removeAttachment = (i: number) => {
  //   setAttachments(attachments.filter((_, index) => index !== i));
  // };
  //
  // React.useEffect(() => {
  //   if (!open) setAttachments([]);
  // }, [open]);
  //

  const defaultValue = React.useContext(DefaultValueContext) as PollDraft;
  const { setPollId } = React.useContext(DraftContext) as DraftActions;

  React.useEffect(() => {
    if (defaultValue?.pollId) setPollId(defaultValue?.pollId);
  }, [defaultValue?.pollId, setPollId]);

  const handleCloseDialog = () => () => {
    if (closeDialog) closeDialog();
  };

  const renderCloseButton = () => (
    <IconButton
      onClick={handleCloseDialog()}
      className="absolute hidden h-5 cursor-pointer sm:!w-3 sm:!h-3 !text-on-secondary sm:!text-brand-primary top-6 sm:top-3 left-6 sm:left-[95%] sm:flex"
    >
      <CloseIcon />
    </IconButton>
  );

  return (
    <Card
      className="flex flex-col w-full h-full m-0 sm:-mt-4 sm:w-[540px] sm:h-auto"
      classes={{
        root: 'overflow-auto',
      }}
    >
      {renderCloseButton()}
      <MakePostHeader isReshareMode={open === 'share'} />
      <MakePostEditor
        showEmojiMenu={showEmojiMenu}
        closeEmojiMenu={() => setShowEmojiMenu(false)}
        isReshareMode={open === 'reshare'}
        contentId={contentId}
      />
      <MakePostFooter
        handleCloseDialog={handleCloseDialog()}
        defaultAttachmentSelected={
          ['photo', 'video'].includes(open || '')
            ? (open as 'photo' | 'video')
            : undefined
        }
        openShowEmojiMenu={() => setShowEmojiMenu(true)}
        isReshareMode={open === 'reshare'}
      />
    </Card>
  );
};

export default MakePostDialog;

// <AttachmentManager attachments={attachments} />
// {/* <SchedulePostDialog */}
// {/*  isOpen={draft.status === DraftStatus.SETTING_PUBLISH_TIME} */}
// {/*  {...draft.actions} */}
// {/*  onClose={draft.cancelScheduling()} */}
// {/* /> */}
// {draft.status === DraftStatus.SUBMITTING ||
// draft.status === DraftStatus.DONE ||
// draft.status === DraftStatus.SCHEDULING ? (
//   <SubmittingDialog show message={t(TextKeys.SharingPost)} />
// ) : (
//   <Card className="flex flex-col w-full h-full m-0 sm:-mt-4 sm:w-[540px] sm:h-auto">
//     {renderCloseButton()}
//     <MakePostHeader {...draft.header} />
//     <MakePostEditor
//       {...draft.defaultValues}
//       {...draft.editor}
//       showEmojiMenu={showEmojiMenu}
//       closeEmojiMenu={() => setShowEmojiMenu(false)}
//       hasLink={attachments.length > 0}
//     />
//
//     <MakePostAttachmentView
//       attachments={attachments}
//       renderLink={() => null} // TODO: fix this
//       removeAttachment={removeAttachment}
//     />
//     {/* <MakePostFooter */}
//     {/*  postType={draft.editor.selectedType as FeedTypes} */}
//     {/*  defaultType={open !== 'close' ? attachmentSelectionType : ''} */}
//     {/*  openShowEmojiMenu={() => setShowEmojiMenu(true)} */}
//     {/*  addNewAttachment={addNewAttachment} */}
//     {/* /> */}
//   </Card>
// )}
