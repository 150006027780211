import { ContentTypes } from '@data/contents/Contents.model';
import {
  FeedPublishStatus,
  FeedSharingOption,
  FeedTypes,
} from '../../models/Feed';
import { AttachmentProps } from '../../models/Timeline';

export interface FeedActor {
  id: number;
  nickName: string;
  avatar: string;
  relation: string;
  reaction: 'hug' | 'like' | 'wow' | 'mad' | 'sad';
}

export interface FeedStatus {
  bookmarked: boolean;
  commentsCount: number;
  commitmentsCount: number;
  committed: boolean;
  doneCount: number;
  enableComments: boolean;
  flagged: boolean;
  flagReviewedAt?: string;
  totalComments?: number;
  reactions: {
    likeCount: number;
    hugCount: number;
    madCount: number;
    sadCount: number;
    wowCount: number;

    like: boolean;
    hug: boolean;
    mad: boolean;
    sad: boolean;
    wow: boolean;
  };
}

export interface Publisher {
  name: string;
  avatar: string | null;
  id: number;
}

export interface PollOption {
  id: number;
  text: string;
  votes: number;
  voted: boolean;
}

export interface Poll {
  id: number;
  active: boolean;
  endTime: string;
  options: PollOption[];
  voted: boolean;
  votedCount: number;
}

export interface Feed {
  id: number;
  parentId?: number;
  status: FeedStatus;
  info: {
    category: FeedTypes;
    description: string | null;
    done: boolean;
    createdAt: number;
    pubDate: string;
    // root?: Asset;
    pubStatus: FeedPublishStatus;
    title: string;
    visibility: FeedSharingOption[];
    parentId?: number;
    flagReviewedAt?: string | null;
    channelId?: number;
  };
  event?: {
    eventId: number;
    eventDate: string;
    //  terms?: string;
    joinedEvent?: boolean;
    joinedEventCount?: number;
    startTime?: string;
    endTime?: string;
    cover?: string;
    isActive?: boolean;
    location?: string;
    isLocationExternal?: boolean;
  };
  adverseEvent?: {
    isAPotentialAE?: boolean;
    pvAutoDetected?: boolean;
    pvManualReviewDone?: boolean;
    pvReported?: boolean;
    curatioCaseId?: number;
    externalId?: string;
    identifiedBy?: string;
    reportedBy?: string;
    reportedAt?: string;
    reportedDate?: string;
    reviewerComment?: string;
  };
  tags: string[];
  comments?: {
    comments: Record<string, Feed>;
    ids: number[];
  };
  publisher: Publisher;
  attachments?: AttachmentProps;
  poll?: Poll;
  reshared?: Feed;
  resharedContent?: {
    id: number;
    title: string;
    cover: string;
    type: ContentTypes;
  };
}

export enum TimelineTypes {
  AGGREGATED = 'aggregated',
  BOOKMARKS = 'bookmarks',
  COMMITMENTS = 'commitments',
  DONE = 'dones',
  FLAGGED = 'flagged',
  DRAFTS = 'draft_posts',
  SCHEDULED = 'scheduled_posts',
  USER_POSTS = 'user_posts',
  GROUP_TIMELINE = 'GROUP_TIMELINE',
}

export interface DraftRequest {
  title: string;
  category: FeedTypes;
  relation: FeedSharingOption;
  description?: string;
  pubDate?: string;
  pubStatus: FeedPublishStatus;
  attachments?: { file: File; content: string }[];
  channelId?: number;
  options?: string[];
  endTime?: {
    day: number;
    hour: number;
    minute: number;
  };
  attachmentLink?: string;
  recipientIds?: string[];
  tags: string[];
  pollId?: number;
  // files,
  // link,
  // channel: FeedSharingOption[],
  // recipientId,
}

export interface PollDraftRequest {
  question: string;
  relation: FeedSharingOption;
  channelId?: number;
  options: string[];
  endTime: string;
}

export enum TimeLines {
  AGGREGATED = 'aggregated',
  BOOKMARKS = 'bookmarks',
  DRAFTED = 'drafts',
  SCHEDULED = 'scheduled_posts',
  USER_POSTS = 'user_posts',
  GROUP_TIMELINE = 'GROUP_TIMELINE',
  ADVERSE_EVENT = 'ADVERSE_EVENT',
  REPORTED_ADVERSE_EVENT = 'REPORTED_ADVERSE_EVENT',
  FLAGGED = 'flagged',
  REVIEWED_FLAGGED = 'reviewed_flagged',
  DETAILS = 'details',
}
