import React from 'react';
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  TextField,
} from '@mui/material';
import Button from '@mui/material/Button';
import useAppDispatch from '@data/useAppDispatch';
import SearchIcon from '@mui/icons-material/Search';
import Avatar from '@shared/components/avatar/Avatar';
import { Size } from '../../../../models/constants';
import { resharePost } from '@data/chat/Chat.thunk';
import useFriends
  from '@shared/components/make-post/components/select-friends/hooks/useFriends';
import { useNavigate } from 'react-router-dom';
import Endpoints from '@view/routes/endpoints';

export type ShareInChatDialogProps = {
  open: boolean;
  onClose: () => void;
  id?: string | number;
  type?: 'post' | 'discover';
};

export const ShareInChatDialog: React.FC<ShareInChatDialogProps> = ({
                                                                      open,
                                                                      onClose,
                                                                      id,
                                                                      type,
                                                                    }) => {
  const dispatch = useAppDispatch();
  const [searchKey, setSearchKey] = React.useState('');

  const { friends, loading } = useFriends(searchKey);


  const [selectedFriends, setSelectedFriends] = React.useState([] as any);

  const renderFriends = React.useCallback(
    (friends, loading, selectedFriends) => {
      if (loading) return <div>Loading...</div>;
      if (!friends.length && !selectedFriends.length)
        return <div>No friends found</div>;

      return (!friends.length ? selectedFriends : friends).map((friend) => {
        const isSelected = selectedFriends.some(
          (selectedFriend) => selectedFriend.id === friend.id,
        );

        return (
          <IconButton
            key={friend.id}
            className='flex items-center w-full mb-3 rounded-none'
            onClick={() => {
              if (isSelected) {
                setSelectedFriends(
                  selectedFriends.filter(
                    (selectedFriend) => selectedFriend.id !== friend.id,
                  ),
                );
              } else {
                setSelectedFriends([...selectedFriends, friend]);
              }
            }}
          >
            <div className='mr-3 w-[40px] h-[40px]'>
              <Avatar src={friend.avatar} size={Size.XSMALL} />
            </div>
            <h6 className='flex-1 text-left h6 text-on-primary'>
              {friend.name}
            </h6>
            <Checkbox checked={isSelected} />
          </IconButton>
        );
      });
    },
    [],
  );

  const navigate = useNavigate();

  const onReshare = () => {
    dispatch(resharePost({
      postId: `${id}`,
      peerIds: selectedFriends.map((friend) => friend.id),
      type,
    })).then((r) => {
      navigate(Endpoints.CHAT);
      onClose();
    });
  };

  if (!id || !type) {
    return null;
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby='responsive-dialog-title'
      className='pl-[70px]'
    >
      <DialogTitle
        className='h4 text-brand-primary'
        id='responsive-dialog-title'
      >
        Send to...
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <TextField
            id='outlined-basic'
            // label="Outlined"
            placeholder='Search friends'
            variant='outlined'
            onChange={(e) => setSearchKey(e.target.value)}
            className='mb-3 !h-[34px] w-[292px]'
            InputProps={{
              className: 'h-[34px] !text-on-primary border-[#D6D9DC]',
              startAdornment: (
                <SearchIcon className='mr-3 -ml-1 text-on-secondary' />
              ),
            }}
          />
          <div className='w-full h-full body1 text-primary min-h-[330px]'>
            {renderFriends(friends, loading, selectedFriends)}
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions className='w-full'>
        <div className='flex items-center justify-center w-full h-full'>
          <Button
            // disabled={hasLoading && isLoading}
            color='primary'
            variant='outlined'
            className='mr-2 w-36'
            onClick={onClose}
          >
            CANCEL
          </Button>
          <Button
            disabled={!selectedFriends.length}
            className='ml-2 w-36'
            onClick={onReshare}
            variant='contained'
            color='primary'
          >
            SEND
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};
