import React from 'react';
import { Card } from '@mui/material';
import { ReactComponent as ReshareComponent } from '@assets/icons/reshare.svg';
import { ReactComponent as ContentIcon } from '@assets/icons/content.svg';
import { Feed } from '@data/timeline/timeline.model';
import { useNavigate } from 'react-router-dom';
import Endpoints, { endpointIDReplacer } from '@view/routes/endpoints';

export const SharedPostDetails: React.FC<{
  post: Feed;
  clickable?: boolean;
  time?: string;
}> = ({ post, clickable, time }) => {
  const reshareMarker = () => {
    return (
      <div
        className='absolute flex items-center justify-center w-10 h-10 rounded-full bg-on-primary opacity-80 left-2'
        style={{
          top: '-20px',
        }}
      >
        <ReshareComponent className='w-3 h-3' />
      </div>
    );
  };

  const renderAttachment = () => {
    if (post?.attachments?.files?.length) {
      return (
        <img
          src={post?.attachments?.files[0].link}
          alt='attachment'
          className='object-cover w-20 h-16 mr-2 rounded-lg'
        />
      );
    }
    return null;
  };

  const navigate = useNavigate();

  return (
    <div
      className={`w-full h-22 mt-3 relative ${
        clickable ? 'cursor-pointer' : ''
      }`}
      onClick={() => {
        if (clickable) {
          navigate(endpointIDReplacer(Endpoints.POST, post?.id));
        }
      }}
    >
      {reshareMarker()}
      <Card className='w-full h-full pt-3 pb-3 pl-2 pr-2 rounded-lg bg-accent'>
        <div className='flex items-start'>
          {renderAttachment()}
          <div className='flex-col'>
            <div className='flex items-center justify-left'>
              <ContentIcon className='w-4 h-4' />
              <p className='ml-2 text-secondary-black h6'>
                {post?.publisher?.name}
              </p>
            </div>
            <p className='mt-2 text-on-background h6 ellipsis2'>
              {post?.info?.description}
            </p>
          </div>
        </div>
        {
          time && (
            <div
              className='w-full text-right mt-3 caption text-on-primary opacity-50'
            >
              {time}
            </div>
          )
        }
      </Card>
    </div>
  );
};
