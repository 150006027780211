import createAsyncThunkWithAuth from '@data/createAsyncThunkWithAuth';
import axios from 'axios';
import { ApiEndpoint } from '@utils/types';

export const fetchConversation = createAsyncThunkWithAuth<void>(
  'chat/fetchConversation',
  async (args, urlGenerator) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          urlGenerator(ApiEndpoint.GET_CHAT_HISTORY, {
            user: args.userId,
          }),
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch(reject);
    });
  },
);

export const fetchMessages = createAsyncThunkWithAuth<{
  page: number;
  peer: number;
}>('chat/fetchMessages', async (args, urlGenerator) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        urlGenerator(ApiEndpoint.GET_MESSAGES, {
          user: args.userId,
          peer: args.peer,
          page: args.page,
        }),
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch(reject);
  });
});

export const sendMessage = createAsyncThunkWithAuth<{
  message: string;
  peer: number;
}>('chat/sendMessage', async (args, urlGenerator) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        urlGenerator(ApiEndpoint.SEND_MESSAGE, {
          user: args.userId,
          peer: args.peer,
        }),
        {
          message: args.message,
        },
      )
      .then((response) => {
        resolve({
          message: response.data.assets,
          userId: args.userId,
        });
      })
      .catch(reject);
  });
});
export const resharePost = createAsyncThunkWithAuth<{
  postId: string;
  peerIds: number[];
  type?: string;
}>('chat/resharePost', async (args, urlGenerator) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        urlGenerator(ApiEndpoint.RESHARE_POST_IN_CHAT, {
          user: args.userId,

        }),
        {
          reshared_asset_id: args.postId,
          peer_ids: args.peerIds,
          type: args.type,
        },
      )
      .then((response) => {
        resolve({
          message: response.data.assets,
          userId: args.userId,
        });
      })
      .catch(reject);
  });
});

export const markAsRead = createAsyncThunkWithAuth<{
  peer: number;
  messageId: number;
}>('chat/markAsRead', async (args, urlGenerator) => {
  return new Promise((resolve, reject) => {
    axios
      .put(
        urlGenerator(ApiEndpoint.CONFIRM_MESSAGE, {
          user: args.userId,
          peer: args.peer,
          messageId: args.messageId,
        }),
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch(reject);
  });
});
