import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {
  DesktopDatePicker as MuiDatePicker,
} from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import React from 'react';
import { TextField } from '@mui/material';

const DatePicker: React.FC<{ setDate }> = ({ setDate }) => {
  const [value, setValue] = React.useState<Date | null>(new Date());
  const [isDatePickerOpen, setIsDatePickerOpen] = React.useState(false);

  React.useEffect(() => {
    setDate(value);
  }, [setDate, value]);

  return (
    <div className='h4 text-on-secondary'>
      <span className='mb-[14px]'>Date</span>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <MuiDatePicker
          open={isDatePickerOpen}
          className='w-full'
          // label="Date"
          value={value}
          onAccept={() => {
            setIsDatePickerOpen(false);
          }}
          onChange={(newValue) => {
            setValue(newValue);
          }}
          inputFormat='MMMM D[,] YYYY'
          disablePast
          disableOpenPicker
          renderInput={(params) => (
            <TextField
              {...params}
              variant='filled'
              className='w-full h2 text-on-primary !mt-[14px] !mb-[28px]'
              classes={{ root: '!h-[50px]' }}
              onClick={() => setIsDatePickerOpen(true)}
              placeholder='Post publish date'
            />
          )}
        />
      </LocalizationProvider>
    </div>
  );
};

export default DatePicker;
