import { Conversation, Message } from './Chat.model';
import { convertTimelineResponse } from '@data/timeline/timeline.factory';
import { getContentType } from '@data/contents/Contents.factory';

export const convertConversationResponse = (
  conversation: any,
): Conversation => {
  return {
    userId: conversation.user_id,
    unreadMessageCount: conversation.new_message_count,
    nickname: conversation.nick_name,
    isOnline: conversation.status === 'Active',
    latestMessage: conversation.latest_message.message,
    latestMessageTime: conversation.latest_message.created_at,
  };
};
export const covertConversationsResponse = (
  conversations: any[],
): Conversation[] => {
  return conversations.map((conversation) => {
    return convertConversationResponse(conversation);
  });
};

export const convertMessageResponse = (message: any): Message => {
  return {
    id: message.id,
    userId: message.by_user,
    message: message.message[0]?.message,
    time: message.message[0]?.created_at,
    resharedPost:
      message.message[0]?.props?.asset ?
        convertTimelineResponse(message.message[0]?.props?.asset) : undefined,
    resharedContent: message.message[0]?.props?.reshared_item ?
      {
        id: message.message[0]?.props?.reshared_item.id,
        type: getContentType(message.message[0]?.props?.reshared_item),
        title: message.message[0]?.props?.reshared_item.title,
        cover: message.message[0]?.props?.reshared_item.cover_url,
      } : undefined,
  };
};
export const convertMessagesResponse = (message: any[]): Message[] => {
  return message.map((message) => {
    return convertMessageResponse(message.activity);
  });
};

export const convertSentMessageResponse = (
  message: any,
  userId: number,
): Message => {
  return {
    id: message.id,
    userId,
    message: message.message,
    time: message.created_at,
  };
};
