// @flow
import * as React from 'react';
import moment from 'moment';
import LoadingSpinner
  from '@hooks/use-loading/useLoading/components/LoadingSpinner';

type MessageBubbleProps = {
  message: string;
  time: string;
  isMine: boolean;
  isPending?: boolean;
};

const MessageBubble: React.FC<MessageBubbleProps> = ({
                                                       message,
                                                       isMine,
                                                       time,
                                                       isPending = false,

                                                     }) => {
  const containerClass = isMine
    ? 'bg-secondary-background text-on-primary rounded-bl-[10px]'
    : 'bg-brand-primary text-white rounded-br-[10px]';

  return (
    <div
      className={`w-full pt-2 pb-1 pr-5 pl-5  flex ${
        isMine ? 'justify-end' : 'justify-start'
      }`}
    >
      <div
        className={`${containerClass} body2 relative w-full max-w-[400px] pt-4 pl-5 pr-3 pb-2 rounded-t-[10px] flex flex-col`}
      >
        <span>{message}</span>
        <span
          className={`w-full text-right mt-3 caption ${
            isMine ? 'text-on-primary opacity-50' : 'text-white opacity-80'
          }`}
        >
          {moment(time).format('HH:mm A')}
        </span>
        {isPending && (
          <div className='absolute w-5 h-5 bottom-3 left-4'>
            <LoadingSpinner />
          </div>
        )}
      </div>
    </div>
  );
};

export default MessageBubble;
