import React from 'react';
import useAppDispatch from '@data/useAppDispatch';
import { TimeLines } from '@data/timeline/timeline.model';
import { useSnackbar } from 'notistack';
import useSharingOptions from '@shared/components/make-post/hooks/useSharingOptions';
import useGroups from '@shared/components/make-post/hooks/useGroups';
import { createFeed, updateFeed } from '@data/timeline/timeline.thunk';
import moment from 'moment-timezone';
import * as linkify from 'linkifyjs';
import { DraftStatus } from '../models/MakePostDraft';
import {
  FeedPublishStatus,
  FeedSharingOption,
  FeedTypes,
} from '../../../../../models/Feed';

export interface Draft {
  id?: string;
  title?: string;
  selectedSharingOption?: string;
  type?: FeedTypes;
  body?: string;
  status?: DraftStatus;
  attachments?: ({ file: File; content: string } | string)[];
  attachmentLink?: string;
  scheduledTime?: Date;
  recipients?: string[];
  tags?: string[];
}

export interface PollDraft extends Draft {
  options?: string[];
  endTime?: { day: number; hour: number; minute: number };
  pollId?: number;
}

export interface DraftActions {
  setTitle: (_?: string) => void;
  setSelectedSharingOption: (_: string) => void;
  setType: (_: FeedTypes) => void;
  setBody: (_: string) => void;
  setAttachments: (_: ({ file: File; content: string } | string)[]) => void;
  setScheduledTime: (_: Date) => void;
  setOptions: (_: string[]) => void;
  setEndTime: (_: { day: number; hour: number; minute: number }) => void;
  reset: () => void;
  setRecipients?: (_: string[]) => void;
  setTags: (_: string[]) => void;
  setPollId: (_?: number) => void;
}

const initiateDraftActions = (
  setDraft: (f: (s: Draft | PollDraft) => Draft | PollDraft) => void,
): DraftActions => {
  const baseFunc = (d: Partial<Draft | PollDraft>) => {
    setDraft((prev) => ({ ...prev, ...d }));
  };
  return {
    setTitle: (title) => baseFunc({ title }),
    setSelectedSharingOption: (selectedSharingOption) =>
      baseFunc({ selectedSharingOption }),
    setType: (type) => baseFunc({ type }),
    setBody: (body) => baseFunc({ body }),
    setAttachments: (attachments) => baseFunc({ attachments }),
    setScheduledTime: (scheduledTime) => baseFunc({ scheduledTime }),
    setOptions: (options) => baseFunc({ options }),
    setEndTime: (endTime) => baseFunc({ endTime }),
    reset: () => baseFunc({}),
    setRecipients: (recipients) => baseFunc({ recipients }),
    setTags: (tags) => baseFunc({ tags }),
    setPollId: (pollId) => baseFunc({ pollId }),
  };
};

export interface DraftShareActions {
  onShare: () => Promise<void>;
  onStartScheduling?: () => void;
  onSchedule: () => Promise<void>;
  onDraft: () => Promise<void>;
  onValidate: () => boolean;
}

export const DraftContext = React.createContext<
  (DraftShareActions & DraftActions) | null
>(null);

const useDraftActions = (
  draftId?: string,
  channelId?: number,
  timeline?: TimeLines,
  isReshared?: boolean,
  contentId?: string,
): [DraftActions, DraftShareActions] => {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [draft, setDraft] = React.useState<Draft>({});

  const draftFeedActions: DraftActions = React.useMemo(
    () => initiateDraftActions(setDraft),
    [],
  );

  React.useEffect(() => {
    if (draft.body) {
      const links = linkify.find(draft.body || '') || [];
      setDraft((prev) => ({
        ...prev,
        attachmentLink: links[0]?.value,
      }));
    }
  }, [draft.body]);

  const myGroups = useGroups();
  const { getSharingOptionKey } = useSharingOptions(myGroups); // TODO - redundant

  const onShare = React.useCallback(
    async (pubStatus) => {
      const [relation, channel] = getSharingOptionKey(
        draft.selectedSharingOption,
      );

      let pubDate = moment().utc().format('YYYY-MM-DD[T]HH:mm:ss') + 'Z';
      if (pubStatus === FeedPublishStatus.SCHEDULED) {
        const timeZone = moment(draft.scheduledTime).format('Z');
        pubDate =
          moment(draft.scheduledTime).utc().format('YYYY-MM-DD[T]HH:mm:ss') +
          timeZone;
      }

      // if (
      //   draft.type === FeedTypes.POLL &&
      //   pubStatus === FeedPublishStatus.SCHEDULED
      // ) {
      //   enqueueSnackbar('Scheduling polls is not supported!', {
      //     variant: 'warning',
      //   });
      //   return;
      // }

      // if (
      //   draft.type === FeedTypes.POLL &&
      //   pubStatus === FeedPublishStatus.DRAFT
      // ) {
      //   enqueueSnackbar('Draft polls is not supported!', {
      //     variant: 'warning',
      //   });
      //   return;
      // }

      // eslint-disable-next-line consistent-return
      return dispatch(
        draftId && !isReshared
          ? updateFeed({
              ...{
                title: draft.title || '',
                category: draft.type as FeedTypes,
                relation: relation as FeedSharingOption,
                description: draft.body,
                pubDate,
                channelId: channel as number,
                attachments: draft.attachments as {
                  file: File;
                  content: string;
                }[],
                pubStatus,
                options: (draft as PollDraft).options,
                endTime: (draft as PollDraft).endTime,
                attachmentLink: draft.attachmentLink,
              },
              id: draftId,
              pollId: (draft as PollDraft).pollId,
              timeline,
              tags: draft.tags || [],
            })
          : createFeed({
              ...{
                isReshared,
                id: isReshared && draftId ? draftId : undefined,
                contentId: isReshared && contentId ? contentId : undefined,
                title: draft.title || '',
                category: draft.type as FeedTypes,
                relation: relation as FeedSharingOption,
                description: draft.body,
                pubDate,
                channelId: channel as number,
                attachments: draft.attachments as {
                  file: File;
                  content: string;
                }[],
                pubStatus,
                options: (draft as PollDraft).options,
                endTime: (draft as PollDraft).endTime,
                attachmentLink: draft.attachmentLink,
                recipientIds: draft.recipients,
                tags: draft.tags || [],
              },
              timeline,
            }),
      );
    },
    [
      dispatch,
      draft,
      draftId,
      getSharingOptionKey,
      timeline,
      isReshared,
      contentId,
    ],
  );

  const onValidate = React.useCallback(() => {
    if (!draft?.title) {
      enqueueSnackbar('Title must be provided!', {
        variant: 'warning',
      });
      return false;
    }

    if (!draft?.tags || (draft?.tags?.length === 0 && !isReshared)) {
      enqueueSnackbar('At lease one tag must be provided!', {
        variant: 'warning',
      });
      return false;
    }

    // const pollOptions = options.filter((option) => option !== '');

    if (
      draft.type === FeedTypes.POLL &&
      ((draft as PollDraft).options || []).length < 2 &&
      !isReshared
    ) {
      // setStatus(DraftStatus.INITIATED);
      enqueueSnackbar('At least two options must be provided!', {
        variant: 'warning',
      });
      return false;
    }
    return true;
  }, [draft, enqueueSnackbar, isReshared]);

  const shareActions: DraftShareActions = React.useMemo(
    () => ({
      onShare: async () => {
        return onShare(FeedPublishStatus.PUBLISHED);
      },
      onSchedule: async () => {
        return onShare(FeedPublishStatus.SCHEDULED);
      },
      onDraft: async () => {
        return onShare(FeedPublishStatus.DRAFT);
      },
      onValidate: () => onValidate(),
    }),
    [onShare, onValidate],
  );

  // Actions
  // React.useEffect(() => {
  //   if (body) {
  //     const links = linkify.find(body || '') || [];
  //     setAttachmentLink(links[0]?.value);
  //   }
  // }, [body]);

  // const draftTimeline = draftId?.split('-')[0] || 'p';
  // const post = useSelector((state) =>
  //   TimelineSelectors[
  //     TimelineSelectors.selectAdapters(draftTimeline)
  //   ].selectById(state, draftId || 0),
  // );
  //
  // const onShare = React.useMemo(
  //   () => (pubStatus) => {
  //     if (!title && !body) {
  //       setStatus(DraftStatus.INITIATED);
  //       enqueueSnackbar('Title or body must be provided!', {
  //         variant: 'warning',
  //       });
  //       return;
  //     }
  //
  //     const pollOptions = options.filter((option) => option !== '');
  //
  //     if (type === FeedTypes.POLL && pollOptions.length < 2) {
  //       setStatus(DraftStatus.INITIATED);
  //       enqueueSnackbar('At least two options must be provided!', {
  //         variant: 'warning',
  //       });
  //       return;
  //     }
  //
  //     // const [relation, channel] = getSharingOptionKey(selectedSharingOption);
  //     const [relation, channel] = [FeedSharingOption.PUBLIC, 0];
  //
  //     const request: DraftRequest = {
  //       title: title || '',
  //       category: type as FeedTypes,
  //       relation: relation as FeedSharingOption,
  //       description: body,
  //       channelId: channel as number,
  //       attachments,
  //       pubStatus,
  //       options: pollOptions,
  //       endTime,
  //       attachmentLink,
  //     };
  //
  //     setDraft(request);
  //
  //     if (pubStatus === FeedPublishStatus.SCHEDULED) {
  //       const timeZone = moment(scheduledTime).format('Z');
  //       request.pubDate =
  //         moment(scheduledTime).utc().format('YYYY-MM-DD[T]HH:mm:ss') +
  //         timeZone;
  //     }
  //
  //     dispatch(
  //       // editMode
  //       //   ? updateFeed({
  //       //       ...request,
  //       //       id: post?.id as number,
  //       //       timeline,
  //       //     })
  //       //   :
  //       createFeed({
  //         ...request,
  //         timeline,
  //       }),
  //     )
  //       .then(() => {
  //         setStatus(DraftStatus.DONE);
  //       })
  //       .catch(() => {
  //         setStatus(DraftStatus.INITIATED);
  //       });
  //   },
  //   [
  //     attachmentLink,
  //     attachments,
  //     body,
  //     dispatch,
  //     editMode,
  //     endTime,
  //     enqueueSnackbar,
  //     options,
  //     // post?.id,
  //     scheduledTime,
  //     selectedSharingOption,
  //     // getSharingOptionKey,
  //     timeline,
  //     title,
  //     type,
  //   ],
  // );
  //
  // React.useEffect(() => {
  //   if (status === DraftStatus.SETTING_PUBLISH_TIME)
  //     setScheduledPost({
  //       title: title || '',
  //       category: type as FeedTypes,
  //       description: body || '',
  //     });
  // }, [body, status, title, type]);
  //
  // React.useEffect(() => {
  //   if (status === DraftStatus.SCHEDULING) onShare(FeedPublishStatus.SCHEDULED);
  //   if (status === DraftStatus.SUBMITTING) onShare(FeedPublishStatus.PUBLISHED);
  //   if (status === DraftStatus.DRAFTING) onShare(FeedPublishStatus.DRAFT);
  //   if (status === DraftStatus.START) resetDraft();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [status]);

  // React.useEffect(() => {
  //   if (draftId) setEditMode(true);
  //   else setEditMode(false);
  // }, [draftId, post]);

  return [draftFeedActions, shareActions];
};

export default useDraftActions;
